import { render, staticRenderFns } from "./isLink.vue?vue&type=template&id=ec33ce9c&scoped=true"
import script from "./isLink.vue?vue&type=script&setup=true&lang=js"
export * from "./isLink.vue?vue&type=script&setup=true&lang=js"
import style0 from "./isLink.vue?vue&type=style&index=0&id=ec33ce9c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.21_css-loader@6.10.0_webpack@5.90.3__lodash@4._53ef9978d451378976f948b1ec92f422/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec33ce9c",
  null
  
)

export default component.exports